import { axios } from '@/utils/request'
import { ref } from 'vue'
import { debounce } from 'throttle-debounce'

export const useGetRegFilingsData = (reportId) => {
    const isLoading = ref(false)
    const errorMessage = ref('')
    const data = ref([])
    
    const path = 'https://storage.zds.zebra-group.ru/data/'
    const companyId ='16'
    const fileName = 'static.html'
    const endpoint = `${path}${companyId}/${reportId}/${fileName}` 

    const fetchData = debounce(300, async () => {
        isLoading.value = true

        try {
            const res = await axios.get(endpoint)
            data.value = res?.docs
        }
        catch {
            errorMessage.value = 'Произошла ошибка. Попробуйте еще раз'
        }
        finally {
            isLoading.value = false
        }
    })

    return { data, fetchData, isLoading, errorMessage }
}
