<template>
    <div :class="['z-logo', `z-logo--size-${size}`]">
        <template v-if="isIndex">
            <img
                :src="buildSrc('logo')"
                class="z-logo__img"
                alt="logo."
            />
            <img
                v-if="signature"
                :src="buildSrc('signature')"
                class="z-logo__signature"
                alt="signature."
                :style="gap ? `margin-top: ${gap}px` : null"
            />
        </template>
        <template v-else>
            <component :is="isIndex ? 'span' : 'a'" :href="`/${lang}/`" class="z-logo__link" :title="title">
                <img
                    :src="buildSrc('logo')"
                    class="z-logo__img"
                    alt="logo."
                />
                <img
                    v-if="signature"
                    :src="buildSrc('signature')"
                    class="z-logo__signature"
                    alt="signature."
                    :style="gap ? `margin-top: ${gap}px` : null"
                />
            </component>
        </template>
    </div>
</template>

<script setup>
import {localize} from '@/utils/i18n'
import {useGetter} from '@/composable/useGetter'

// props
const props = defineProps({
    isIndex: {
        type: Boolean
    },
    siteDir: {
        type: String,
        default: '/',
        required: true
    },
    signature: {
        type: Boolean,
        default: true
    },
    theme: {
        type: String,
        validator: prop => [
            'default',
            'white',
            'black',
            'blue'
        ].includes(prop),
        default: 'default'
    },
    size: {
        type: String,
        validator: prop => [
            'l',
            'm'
        ].includes(prop),
        default: 'l'
    },
    gap: {
        type: Number
    }
})

// data
const title = localize({
    ru: 'Перейти на главную страницу',
    en: 'Go to main page'
})
const { lang } = useGetter(['lang'])

// method
const buildSrc = (file) => {
    let src = `/images/${file}/${lang.value}/${file}--${props.theme}`

    return `${src}.svg`
}
</script>

<style lang="scss" src="./index.scss"></style>
