<template>
    <footer class="footer">
        <div class="footer__body">
            <div class="footer__wrapper container">
                <div class="footer__grid">
                    <div class="footer__col">
                        <z-caption size="l" tag="h5" v-html="text.postAddress.title"></z-caption>
                        <p class="text-size-xs" v-html="text.postAddress.address"></p>
                    </div>
                    <div class="footer__col">
                        <z-caption size="l" tag="h5" v-html="text.moscowOffice.title"></z-caption>
                        <p class="text-size-xs" v-html="text.moscowOffice.address"></p>
                    </div>
                    <div class="footer__col">
                        <z-caption size="l" tag="h5" v-html="text.investorsTitle"></z-caption>
                        <z-link href="mailto:ir@cian.ru" size="xs">ir@cian.ru</z-link>
                        <z-link href="tel:+74952803814" size="xs">+7 (495) 280-38-14</z-link>
                    </div>
                    <div class="footer__col footer__col--fourth">
                        <z-caption size="l" tag="h5" v-html="text.mediaTitle"></z-caption>
                        <z-link href="mailto:pr@cian.ru" size="xs">pr@cian.ru</z-link>
                    </div>
                </div>
                <div class="footer__social">
                    <z-caption size="l" tag="h5" v-html="text.social.title"></z-caption>
                    <ul>
                        <li>
                            <a class="footer__social--link" href="https://t.me/cian_ir" target="_blank">
                                <z-icon name="social/telegram-footer" height="32" width="32"></z-icon>
                                <p class="text-size-xs" v-html="text.social.telegram"></p>
                            </a>
                        </li>
                        <li>
                            <a class="footer__social--link" href="https://www.tbank.ru/invest/social/profile/Cian_IR/"
                               target="_blank">
                                <z-icon name="social/tinkoff-pulse-footer" height="32" width="32"></z-icon>
                                <p class="text-size-xs" v-html="text.social.tbank"></p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="footer__bottom">
                <navigation-bottom :data="footerNavData"/>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {localize} from '@/utils/i18n'
import NavigationBottom from '@/components/NavigationBottom'
import {useGetter} from '@/composable/useGetter'

const {app} = useGetter(['app'])
const footerNavData = app.value.components.navigation.footer || []

const text = {
    postAddress: {
        title: localize({
            ru: 'Почтовый адрес',
            en: 'Mailing address'
        }),
        address: localize({
            ru: '236006, Калининградская область,<br> г.о. город Калининград,<br> г. Калининград, ул. Октябрьская, д.&nbsp;37, помещ. XIV, офис&nbsp;13',
            en: '236006, Kaliningrad region,<br> Kaliningrad city district,<br> Kaliningrad, 37, Oktyabrskaya str.,<br> XIV, office&nbsp;13'
        })
    },
    moscowOffice: {
        title: localize({
            ru: 'Московский офис',
            en: 'Moscow office'
        }),
        address: localize({
            ru: 'ул. Электрозаводская,<br> д.&#160;27 стр.&#160;8<br> 107023, Москва, Россия',
            en: '27&#160;bld.&#160;8,<br> Electrozavodskaya street<br> Moscow, 107023, Russia'
        })
    },
    investorsTitle: localize({
        ru: 'Связи с&#160;инвесторами',
        en: 'Investor Relations'
    }),
    mediaTitle: localize({
        ru: 'Связи со&#160;СМИ',
        en: 'Media'
    }),
    social: {
        title: localize({
            ru: 'Социальные сети',
            en: 'Social media'
        }),
        telegram: localize({
            ru: 'Телеграм-канал для инвесторов и&#160;аналитиков',
            en: 'Telegram channel for investors and analysts'
        }),
        tbank: localize({
            ru: 'Официальный канал в&#160;Пульсе',
            en: 'Official channel in&#160;Pulse'
        })
    },
}
</script>

<style lang="scss">
.footer {
    &__body {
        padding: 54px 0;
        background-color: $token-colors-bg-3;

        @include breakpoint(tablet) {
            @include padding-level(top, L);
            @include padding-level(bottom, L);
        }

        .z-caption {
            margin-bottom: $token-spacers-2-xs;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        gap: $token-spacers-xs;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            gap: $token-spacers-m;
        }
    }

    &__grid {
        max-width: 1220px;
        width: 100%;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(4, 1fr);
        gap: 0 $token-spacers-xs;

        @include breakpoint(laptop) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint(tablet) {
            row-gap: $token-spacers-m;
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(mobile) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        max-width: 287px;

        &--fourth {
            @include breakpoint(laptop) {
                grid-column: 3 / 4;
            }
            @include breakpoint(tablet) {
                grid-column: 2 / 2;
            }
            @include breakpoint(mobile) {
                grid-column: 1 / 1;
            }
        }
    }

    &__social {
        max-width: 244px;

        &--link {
            display: flex;
            align-items: center;
            color: $token-colors-black-60;
            text-decoration: none;
            gap: $token-spacers-3-xs;
        }

        .z-icon {
            flex-shrink: 0;
        }

        > li:not(:last-child) {
            margin-bottom: $token-spacers-2-xs;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        @include padding-level(top, M);
        @include padding-level(bottom, M);
        border-top: 1px solid $token-colors-black-5;

        @include breakpoint(v-tablet) {
            flex-wrap: wrap;
        }
    }
}
</style>
