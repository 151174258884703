<template>
    <div
        v-if="data && data.length"
        class="reports-container">
        <z-preloader v-if="isLoading" />
        <div
            v-else
            class="reports-container__table">
            <z-select
                v-if="filters && filters?.length > 1"
                class="reports-container__filter"
                v-model="selectedYear"
                is-filter
                :options="filters"
                name="year"
                :placeholder="computedData.placeholderText" />
            <component
                :is="computedData.componentTemplate"
                :data="filteredData" />
        </div>
    </div>
    <z-not-found class="u-top-margin--m" v-else></z-not-found>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import { useRegFilingsHandlers } from '../composable/useRegFilingsHandlers'

import QuarterTemplate from '../templates/QuarterTemplate.vue'
import AnnualTemplate from '../templates/AnnualTemplate.vue'
import EmissionTemplate from '../templates/EmissionTemplate.vue'
import OthersTemplate from '../templates/OthersTemplate.vue'

import { useGetRegFilingsData } from '../composable/useGetRegFilingsData'

const props = defineProps({
    requestKey: {
        type: String,
        required: true
    }
})
const selectedYear = ref('')

const computedData = computed(() => {
    let componentTemplate
    let filterType
    let placeholderText

    switch (props.requestKey) {
    case '7':
        componentTemplate = QuarterTemplate
        filterType = 'quarter'
        placeholderText = 'Год отчета'
        break
    case '4':
        componentTemplate = AnnualTemplate
        filterType = 'year'
        placeholderText = 'Год отчета'
        break
    case '3':
        componentTemplate = EmissionTemplate
        filterType = 'timestamp'
        placeholderText = 'Год публикации'
        break
    case '8':
        componentTemplate = OthersTemplate
        filterType = 'timestamp'
        placeholderText = 'Год публикации'
        break
    }
    return { componentTemplate, filterType, placeholderText }
})

const { fetchData, data, isLoading } = useGetRegFilingsData(props.requestKey)
const { filters, filteredData } = useRegFilingsHandlers(
    data,
    selectedYear,
    computedData.value.filterType
)

onBeforeMount(() => {
    fetchData()
})
</script>

<style lang="scss">
.reports-container {
    &__filter {
        margin-bottom: 64px;
        width: 50%;
    }
}
</style>
