import { localize } from '@/utils/i18n'
import { text } from '../locales'
import { computed } from 'vue'

export const useComputedData = (params, props) => {
    const successMessage = computed(() => {
        const successMessage = localize({
            ru: `На почтовый адрес <span class="u-bold button-bordered-default-text">${params.value.email}</span> было отправлено письмо с подтверждением подписки`,
            en: `An email confirming the subscription was sent to &nbsp;<span class="u-bold button-bordered-default-text">${params.value.email}</span>`
        })

        switch (params.value.state) {
        case 'requestEdit':
        case 'requestUnsubscribe':
            return successMessage
        case 'edit':
            return text.editSuccessTitle
        case 'unsubscribe':
            return text.unsubscribeTitle
        default:
            return successMessage
        }
    })

    const successDescription = computed(() => {
        const editDescription = localize({
            ru: `Вы можете менять настройки подписки на странице <z-link href="./?id=${props.subscriber?.id}&confirm-code=${props.subscriber['confirm-code']}&email=${props.subscriber?.email}&state=edit">&laquo;Управление подпиской&raquo;.</z-link>`,
            en: `You can change your subscription settings on&nbsp;the <z-link href="./?id=${props.subscriber?.id}&confirm-code=${props.subscriber['confirm-code']}&email=${props.subscriber?.email}&state=edit">Manage Subscription page.</z-link>`
        })

        switch (params.value.state) {
        case 'requestEdit':
            return ''
        case 'requestUnsubscribe':
            return ''
        case 'edit':
            return editDescription
        case 'unsubscribe':
            return text.unsubscribeDescription
        default:
            return ''
        }
    })

    const policyText = computed(() => {
        return localize({
            ru: `Настоящим я&nbsp;даю свое <z-link :underlined="false" href="/files/ru/consent_to_the_processing_of_the_personal_data.pdf" target="_blank">согласие</z-link> на&nbsp;обработку моих персональных данных в&nbsp;соответствии с&nbsp;<z-link :underlined="false" href="${props.policyLink}" target="_blank">Политикой конфиденциальности</z-link>.`,
            en: `I&nbsp;hereby consent to&nbsp;the processing of&nbsp;the personal data that&nbsp;I have provided and declare my&nbsp;agreement with <z-link :underlined="false" href="${props.policyLink}" target="_blank">Privacy policy</z-link>.`
        })
    })

    const submitBtnText = computed(() => params.value.state === 'subscribe' ? text.subscribe : text.change)

    const formTitle = computed(() => {
        if (params.value.state === 'subscribe') return text.subscribeTitle
        return text.editTitle
    })

    const formDescription = computed(() => {
        const subscribeDescription = localize({
            ru: 'Если вы&nbsp;уже подписаны и&nbsp;хотите изменить настройки подписки&nbsp;&mdash; все нужное есть на&nbsp;странице <z-link :underlined="false" href="./?state=requestEdit">&laquo;Управление подпиской&raquo;.</z-link>',
            en: 'If&nbsp;you are already subscribed and want to&nbsp;change your subscription settings, you can find everything you need on&nbsp;the <z-link :underlined="false" href="./?state=requestEdit">Manage subscription page.</z-link>'
        })

        return params.value.state === 'subscribe' ? subscribeDescription : ''
    })

    const followTelegram = computed(() => {
        return localize({
            ru: 'Вы&nbsp;также можете следить за&nbsp;новостями в&nbsp;<z-link :underlined="false" href="https://t.me/+aBFNchGgTFJlZGUy" target="_blank">нашем Telegram-канале для инвесторов</z-link>',
            en: 'You can also follow the news in&nbsp;our&nbsp;<z-link :underlined="false" href="https://t.me/+aBFNchGgTFJlZGUy" target="_blank">Telegram channel for investors</z-link>.'
        })
    })

    const errorsMap = computed(() => {
        const alreadySubribeError = computed(() => {
            return localize({
                ru: `Этот адрес уже подписан на рассылку. Перейдите на страницу <z-link theme="dark" href="./?state=requestEdit&email=${params.value.email}">Управление подпиской</z-link> для изменения настроек.`,
                en: `This address is already subscribed. Go to the <z-link theme="dark" href="./?state=requestEdit&email=${params.value.email}">Manage subscription page</z-link> to change the settings.`
            })
        })

        return ({
            'email-13': alreadySubribeError.value,
            'email-12': localize({
                ru: 'Данный email не подписан на рассылку',
                en: 'This email is not subscribed to the newsletter'
            }),
            'email-14': localize({
                ru: 'Данный email не подтвержден, ',
                en: 'This email has not been verified, '
            })
        })

    })


    return {
        successMessage,
        successDescription,
        policyText,
        submitBtnText,
        formTitle,
        formDescription,
        errorsMap,
        followTelegram
    }
}
