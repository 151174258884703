<template>
    <div class="slider">
        <div class="slider__wrapper">
            <button v-if="showNavButtons" class="slider__nav-btn slider__nav-btn--prev" ref="prev">
                <z-icon
                    name="arrows/caret-slider"
                />
            </button>
            <div class="swiper-container slider__container" ref="slider">
                <div class="swiper-wrapper">
                    <slot></slot>
                </div>
            </div>

            <button v-if="showNavButtons" class="slider__nav-btn slider__nav-btn--next" ref="next">
                <z-icon
                    name="arrows/caret-slider"
                />
            </button>
        </div>
        <!-- <div class="slider__controls">
        </div> -->
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'slider',
    data () {
        return {
            slider: null
        }
    },
    props: {
        swiperOptions: {
            type: Object
        },
        effect: {
            type: String,
            default: 'slide'
        },
        showNavButtons: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        init () {
            let options = {
                observer: true,
                loop: true,
                observeParents: true,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                effect: this.effect,
                slidesPerView: 1,
                on: {
                    slideChange () {
                    }
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
            }

            this.slider = new Swiper(this.$refs.slider, Object.assign({}, this.swiperOptions, options))
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
.slider {
    $parent: &;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint (mobile) {
        width: auto;
    }

    &__wrapper {
        width: 100%;
        display: flex;
    }

    img {
        display: block;
        width: 100%;
    }

    &__container {
        border-radius: 5px;
    }

    &__controls {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        width: 100%;
        justify-content: space-between;
        // padding: 0px $token-spacers-xs;

        @include breakpoint (v-tablet) {
            // padding: 0 $token-spacers-2-xs;
        }
    }

    &__nav-btn {
        border: none;
        pointer-events: all;
        background: none;
        padding: 0;
        transition: all $transition;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .z-icon {
            width: 80px;
            height: 80px;

            path {
                fill: $token-colors-button-bordered-default;
            }

            @include breakpoint(v-tablet) {
                width: 64px;
                height: 64px;
            }
        }

        &--prev,
        &--next {
            @include breakpoint (mobile) {
                display: none;
            }
        }

        &--prev {
            margin-right: $token-spacers-xs;

            &:hover {
                transform: translateX(-2px);
            }

            .z-icon {
                transform: rotate(-90deg);
            }
        }

        &--next {
            margin-left: $token-spacers-xs;

            &:hover {
                transform: translateX(2px);
            }

            .z-icon {
                transform: rotate(90deg);
            }
        }
    }

    .swiper-slide {
        height: auto;
        border-radius: 5px;

        &__content,
        &__wrapper {
            height: 100%;
        }
    }

    .swiper-container {
        width: 100%;
    }

    .swiper-pagination {
        @include margin-level (top, S);
        width: 100%;
        position: relative;
        z-index: 8;
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin-right: 18px;
        transition: transform $transition;

        &-active {
            transform: scale(2);
            background: $token-colors-primary;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

</style>
